<template>
  <main>
    <documents :bid="bid" :folder="rid" has-email @email="email()"/>
  </main>
</template>

<script>
import { mapGetters } from 'vuex';
import relation from './relation';
import Documents from '@/components/Documents';
export default {
  name: 'RelationDocuments',
  mixins: [relation],
  components: { Documents },
  computed: {
    ...mapGetters('brokers', ['broker']),
    ...mapGetters('relation', {relation: 'record'}),
  },
  methods: {
    email () {
      this.$router.push({
        name: 'relation-email',
        params: {bid: this.bid, rid: this.rid},
        query: {templateId: 'vPCOw5qxTVkugZBTI6ZZ'}
      });
    }
  },
  metaInfo() {
    return {
      title: [this.$route.query.title, this.$t('documents_title')]
        .join(' ')
        .trim()
    };
  }
};
</script>
