<template>
  <main>
    <div class="p-4" style="padding-bottom: 0;">
      <div class="field hidden-print">
        <sqr-router-link
          class="field"
          icon="plus"
          label="policy_add"
          :to="{
            name: 'relation-policy-add',
            params: { bid, rid }
          }"
          color="primary"
        />
      </div>
    </div>
    <sqr-progress v-show="loading" />
    <sqr-error :error="error" />
    <policies :bid="bid" :rid="rid" />
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import relation from './relation';
import Policies from '@/components/Policies';

export default {
  name: 'RelationPolicies',
  mixins: [relation],
  components: { Policies },
  props: {
    bid: String,
    rid: String
  },
  computed: {
    ...mapGetters('policies', ['loading', 'error'])
  }
};
</script>
